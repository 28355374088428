<template>
  <div class="adaptation-project">
    <div style="background:#fff">
      <div class="flex between" style="padding-bottom: 30px; padding-top: 43px;">
        <div class="title">消息详情</div>
        <el-button type="primary" style="float: right" @click="handleReturn">返回上一级</el-button>
      </div>
      <!-- 数据加载框 -->
      <div class="loading" v-show="loading" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.7)">
      </div>
      <div class="content" v-if="!loading && list.length>0">
        <div style="margin-bottom: 20px;">系统管理员为您推送了以下政策信息：</div>
        <ul>
          <li v-for="(item, i) in list" :key="i" @click="
              goToDetail(
                item.policyId,
                item.id,
              )
            " class="li_card">
            <div v-show="!item.state" class="unread"></div>
            <div class="policy_name">
              {{ item.projectName }}
            </div>
            <div style="height: 24px" v-if="!item.supportmethods"></div>
            <ul v-if="item.supportmethods">
              <li v-for="item2 in item.supportmethods" :key="item2" :class="{
                  fundSupport: item2 == '资金扶持',
                  equityInvestment: item2 == '股权投资',
                  titleRecognition: item2 == '称号认定',
                  taxAndFeeReductions: item2 == '减税降费',
                  financialSupport: item2 == '金融扶持',
                  others: item2 == '其他'
                }">
                {{ item2 }}
              </li>
              <li v-if="item.supportmethods[0] == '资金扶持' && item.money > 0" style="border: 1px solid rgb(229, 229, 229);color: rgb(141, 141, 141);">
                {{ item.money }}万元
              </li>
            </ul>
            <div>
              <span>{{ item.department }}</span>
              <span :class="{ red: parseInt(item.time) <= 30 }" v-if="parseInt(item.time) > 0">剩{{ parseInt(item.time) }}天</span>
              <span style="color: #8d8d8d" v-else>已截止</span>
            </div>
          </li>
        </ul>
        <el-pagination 
          v-show="total > 0" 
          background 
          layout="prev, pager, next" 
          :page-size="pagesize" 
          :total="total" 
          @prev-click="prevClick" 
          @next-click="nextClick" 
          @current-change="currentChange" 
          :current-page="page">
        </el-pagination>
      </div>
      <div v-if="!loading && list.length <= 0">
        <el-empty description="暂无内容"></el-empty>
      </div>
    </div>

  </div>
</template>

<script>
import { request } from '../../../network';
import { getMemberId, getEntityId } from '@/assets/public/utils/token';
export default {
  data () {
    return {
      radio: '0',
      radio2: '0',
      page: 1,
      pagesize: 9,
      total: 100,
      list: [],
      whichpath: 'push',
      hasBindEntity: true,
      dialogVisible: false,
      restaurants: [],
      loading: false
    };
  },
  created () {
    this.getAdaptationProject(this.radio, this.radio2);
    this.getAccountInformation();
  },
  mounted () { },

  computed: {},
  // inject:["reload"],
  methods: {
    async getAccountInformation () {
      if (getMemberId() !== '') {
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/PersonalCenter/accountInformation',
          params: {
            id: getMemberId()
          }
        });
        if (res.code !== 200) return this.$message.error('信息获取失败');
        this.hasBindEntity = !!res.data[0].name;
        return Promise.resolve();
      }
    },
    async getAdaptationProject (status, sort) {
      this.loading = true;
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/getNoticeInfo',
        params: {
          memberId: getMemberId(),
          noticeId: this.$route.query.id,
          noticeType: 4,
          pageNo: this.page,
          pageSize: this.pagesize
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      if (res.data.list) {
        res.data.list.map((item) => {
          if (new Date() > new Date(item.declareDate)) {
            item.time = 0;
          } else {
            const data = new Date(item.declareDate) - new Date();
            item.time = data / (24 * 3600 * 1000);
          }
        });
        this.total = res.data.total;
        this.list = res.data.list;
      } else {
        this.total = 0;
        this.list = [];
      }
      this.loading = false;
      // this.reload();
    },
    goToDetail (id, inputId, sysFollowPolicyId) {
      this.$router.push({
        path: '/formaldetails/jiedu',
        query: {
          id: id,
          inputId: inputId,
          whichpath: this.whichpath
        }
      });
    },
    prevClick () {
      this.page--;
      this.getAdaptationProject(this.radio, this.radio2);
    },
    nextClick () {
      this.page++;
      this.getAdaptationProject(this.radio, this.radio2);
    },
    currentChange (val) {
      this.page = val;
      this.getAdaptationProject(this.radio, this.radio2);
    },
    handleReturn () {
      this.$router.back();
    },
  }
};
</script>

<style lang="less" scoped>
.adaptation-project {
  .condition {
    display: flex;
    font-size: 14px;
    margin-bottom: 30px;
    & > div {
      margin-right: 50px;
      /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #fff;
      }
    }
  }
  .title {
    color: rgba(16, 16, 16, 100);
    font-size: 24px;
    text-align: left;
    font-family: PingFangSC-regular;
  }
  .content {
    & > ul {
      // width: 1275px;
      display: flex;
      flex-wrap: wrap;
      & > li {
        position: relative;
        width: 350px;
        height: 160px;
        margin-right: 75px;
        margin-bottom: 20px;
        border: 1px solid #cccccc;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 23px 16px 21px 17px;
        &:nth-child(3n){
          margin-right: 0;
        }
        .unread {
          width: 8px;
          height: 8px;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
        .policy_name{
          height: 38px;
          font-size: 16px; 
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp:2;
          -webkit-box-orient: vertical;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        & > div:first-child {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        & > ul {
          display: flex;
          margin-top: 16px;
          white-space: nowrap;
          overflow: hidden;
          li {
            height: 24px;
            line-height: 24px;
            padding: 0 6px;
            font-size: 12px;
            border-radius: 3px;
            margin-left: 10px;
            border: 1px solid #e5e5e5;
            background-color: #fff;
            color: #8d8d8d;
            box-sizing: border-box;
            &:first-child {
              margin-left: 0;
            }
            &.fundSupport {
              border: none;
              background-color: #f5831d;
              color: #fff;
            }
            &.titleRecognition {
              border: none;
              background-color: #11cc88;
              color: #fff;
            }
            &.equityInvestment {
              border: none;
              background-color: #11cc88;
              color: #fff;
            }
            &.taxAndFeeReductions {
              border: none;
              background-color: #e91f0a;
              color: #fff;
            }
            &.financialSupport {
              border: none;
              background-color: #6cc2ed;
              color: #fff;
            }
            &.others {
              border: none;
              background-color: rgba(0, 0, 0, 0.4);
              color: #fff;
            }
          }
        }
        & > div:last-child {
          display: flex;
          font-size: 14px;
          margin-top: 21px;
          span:first-child {
            width: 177px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #8d8d8d;
          }
          span:last-child {
            flex: 1;
            text-align: end;
            color: #8d8d8d;
          }
          span.red {
            color: red;
          }
        }
      }
    }
    .el-pagination {
      margin-top: 20px;
      text-align: center;
    }
    // .li_card :hover{
    //     background-color: red;
    //   }
  }
}
.active {
  background: #11cc88;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #156ed0;
}
.creditCode {
  display: flex;
  .div-code {
    margin-right: 5px;
    text-align: center;
    /deep/.el-input__inner {
      width: 40px;
      height: 30px;
      font-size: 12px;
    }
  }
}
.bind-btn-box {
  display: flex;
  justify-content: center;
  width: 100%;
}
.bind-btn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  border: none;
  width: 320px;
  height: 46px;
  background: #156ed0;
  border-radius: 5px;
  cursor: pointer;
}
.goto-bind {
  margin-right: 10px;
  width: 120px;
  height: 42px;
  border: 1px solid #156ed0;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #156ed0;
}
.hasBindEntity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.information_color {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
}
.li_card:hover {
  cursor: pointer;
  border: 2px solid #b7cef5 !important;
}
.li_card {
  border: 1px solid #cccccc;
}
/deep/.el-form-item {
  width: 60%;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
/deep/.el-input__inner {
  font-size: 16px;
  // width:150%;
  height: 50px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.loading {
  height: 300px;
}
.el-button--primary {
  background-color: #156ed0;
  border-color: #156ed0;
}
</style>
