var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adaptation-project"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"flex between",staticStyle:{"padding-bottom":"30px","padding-top":"43px"}},[_c('div',{staticClass:"title"},[_vm._v("消息详情")]),_c('el-button',{staticStyle:{"float":"right"},attrs:{"type":"primary"},on:{"click":_vm.handleReturn}},[_vm._v("返回上一级")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"loading",attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(255, 255, 255, 0.7)"}}),(!_vm.loading && _vm.list.length>0)?_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_vm._v("系统管理员为您推送了以下政策信息：")]),_c('ul',_vm._l((_vm.list),function(item,i){return _c('li',{key:i,staticClass:"li_card",on:{"click":function($event){return _vm.goToDetail(
              item.policyId,
              item.id
            )}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.state),expression:"!item.state"}],staticClass:"unread"}),_c('div',{staticClass:"policy_name"},[_vm._v(" "+_vm._s(item.projectName)+" ")]),(!item.supportmethods)?_c('div',{staticStyle:{"height":"24px"}}):_vm._e(),(item.supportmethods)?_c('ul',[_vm._l((item.supportmethods),function(item2){return _c('li',{key:item2,class:{
                fundSupport: item2 == '资金扶持',
                equityInvestment: item2 == '股权投资',
                titleRecognition: item2 == '称号认定',
                taxAndFeeReductions: item2 == '减税降费',
                financialSupport: item2 == '金融扶持',
                others: item2 == '其他'
              }},[_vm._v(" "+_vm._s(item2)+" ")])}),(item.supportmethods[0] == '资金扶持' && item.money > 0)?_c('li',{staticStyle:{"border":"1px solid rgb(229, 229, 229)","color":"rgb(141, 141, 141)"}},[_vm._v(" "+_vm._s(item.money)+"万元 ")]):_vm._e()],2):_vm._e(),_c('div',[_c('span',[_vm._v(_vm._s(item.department))]),(parseInt(item.time) > 0)?_c('span',{class:{ red: parseInt(item.time) <= 30 }},[_vm._v("剩"+_vm._s(parseInt(item.time))+"天")]):_c('span',{staticStyle:{"color":"#8d8d8d"}},[_vm._v("已截止")])])])}),0),_c('el-pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.pagesize,"total":_vm.total,"current-page":_vm.page},on:{"prev-click":_vm.prevClick,"next-click":_vm.nextClick,"current-change":_vm.currentChange}})],1):_vm._e(),(!_vm.loading && _vm.list.length <= 0)?_c('div',[_c('el-empty',{attrs:{"description":"暂无内容"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }